export function getAppointmentNameFromService(serviceName: string): string {
  const nameParts = serviceName.split("|");

  if (nameParts.length === 1) {
    return nameParts[0];
  }

  let appointmentName = nameParts[1].trimStart();

  if (appointmentName.match(/^\d/)) {
    appointmentName = appointmentName.replace(/[0-9]{1,2}/, "").trimStart();
  }

  return appointmentName;
}
